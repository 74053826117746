@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

* {
	margin: 0;
	padding: 0;
	font-family: 'Lato', sans-serif;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

html::-webkit-scrollbar {
	width: 4px;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
}

html::-webkit-scrollbar-thumb {
	background-color: #63bb6c;
	background-clip: content-box;
	transition: 0.2s ease-out;
	border-radius: 30px;
}

html::-webkit-scrollbar-thumb:hover {
	background-color: #9ae0a1;
	transition: 0.2s ease-out;
}

body {
	margin: 0;
	font-family: 'Lato', sans-serif;
	box-sizing: border-box;
	position: relative;
	z-index: -2;
}

#root {
	position: relative;
	z-index: -2;
}

.apexcharts-menu-icon {
	display: none;
}

/* *************************************************** */
/* Mui Styles */
/* .css-1tomnt9.Mui-selected,
.css-1gdrrci-MuiTypography-root.Mui-selected,
.css-o5evpx-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
	color: #fff !important;
} */

.css-17ebzsf-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
	color: #fff !important;
}

.css-o5evpx-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
	color: #fff !important;
}

/* .css-1ji7omv-muibackdrop-root {
	background-color: rgba(255, 255, 255);
	opacity: 1;
} */

/* Mui Styles */
/* *************************************************** */

/* *************************************************** */
/* TREE GRAPH STYLES*/
div.custom-container {
	/* background-color: #242424; */
	padding: 3rem;
}

svg.custom .node circle {
	fill: #fff;
	stroke: #3caa48;
	stroke-width: 2px;
	r: 8;
}

svg.custom .node text {
	font-size: 15px;
	font-weight: 600;
	/* background-color: #444; */
	fill: #000;
}

svg.custom .node {
	cursor: pointer;
}

svg.custom path.link {
	fill: none;
	stroke: #eee;
	stroke-width: 2px;
}

/* TREE GRAPH STYLES*/
/* *************************************************** */

/* *************************************************** */
/* REACT SWIPER STYLES */
.swiper {
	width: 100%;
	margin: 0 auto;
	/* padding: 0.5rem 1rem; */
}

.cube-swiper {
	padding: 0.5rem 1rem;
}

.whyGic {
	padding: 0;
	width: 100%;
	margin: 0 auto;
}

.bacteria_tile .swiper-wrapper {
	background-color: #fff;
}

.productSwiper .swiper-slide {
	/* width: 25%;
	height: 100%; */
	opacity: 0.4;
}

.productSwiper .swiper-slide-thumb-active {
	opacity: 1;
}

.stepperThumb .swiper-wrapper {
	margin: 1.5rem 0;
	position: relative;
	z-index: 444;
}

.stepperThumb .swiper-slide .slider_paging {
	filter: grayscale(1);
	background: rgba(255, 255, 255, 1);
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	border-radius: 8px;
}

.stepperThumb .swiper-slide-thumb-active .slider_paging {
	border-radius: 8px;
	filter: grayscale(0);
	opacity: 1;
	transition: 0.2s ease-out;
	box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.07);
	transform: translateY(-1.1rem) scale(1.01);
	position: relative;
	z-index: 888;
}

.swiper-slider img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.swiper-pagination {
	bottom: 0;
}

/* .mySwiper .swiper-wrapper {
	margin: 3rem 0;
	padding: 3rem 0;
} */

.swiper-pagination-bullet {
	width: 10px;
	height: 10px;
	text-align: center;
	line-height: 20px;
	font-size: 12px;
	color: #000;
	opacity: 1;
	background: rgba(0, 0, 0, 0.2);
}

.swiper-pagination-bullet-active {
	color: #fff;
	background: #1a5735;
}

/* REACT SWIPER STYLES */
/* *************************************************** */

/* *************************************************** */
/* HEART RATE ANIMATION */

.heart-rate {
	width: 150px;
	height: 73px;
	position: relative;
	margin: 20px auto;
}

.fade-in {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #ffffff;
	top: 0;
	right: 0;
	animation: heartRateIn 2.5s linear infinite;
}

.fade-out {
	position: absolute;
	width: 120%;
	height: 100%;
	top: 0;
	left: -120%;
	animation: heartRateOut 2.5s linear infinite;
	background: rgba(255, 255, 255, 1);
	background: -moz-linear-gradient(left,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 1) 50%,
			rgba(255, 255, 255, 0) 100%);
	background: -webkit-linear-gradient(left,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 1) 50%,
			rgba(255, 255, 255, 0) 100%);
	background: -o-linear-gradient(left,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 1) 50%,
			rgba(255, 255, 255, 0) 100%);
	background: -ms-linear-gradient(left,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 1) 50%,
			rgba(255, 255, 255, 0) 100%);
	background: linear-gradient(to right,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 1) 80%,
			rgba(255, 255, 255, 0) 100%);
}

@keyframes heartRateIn {
	0% {
		width: 100%;
	}

	50% {
		width: 0;
	}

	100% {
		width: 0;
	}
}

@keyframes heartRateOut {
	0% {
		left: -120%;
	}

	30% {
		left: -120%;
	}

	100% {
		left: 0;
	}
}

/* HEART RATE ANIMATION */
/* *************************************************** */

/* *************************************************** */
/* STEPPER PROGRESS BAR */

.transitionStep {
	filter: grayscale(1);
	/* display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%; */
}

.transitionStep.accomplished {
	filter: grayscale(0);
}

.RSPBprogressBar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 8px;
	width: 50%;
	background-color: red;
}

.landingNeedGuts ul {
	margin-left: 20px;
}

.ql-align-center {
	text-align: center !important;
}

.ql-align-justify {
	text-align: justify !important;
}

.ql-align-right {
	text-align: right;
}

/* STEPPER PROGRESS BAR */
/* *************************************************** */

.mission p span {
	font-size: 2rem !important;
}

.mission p strong {
	font-size: 2rem !important;
}

.mission p {
	font-size: 2rem !important;
}

.whoarewe p span {
	font-size: 2rem !important;
}

.whoarewe p {
	font-size: 2rem !important;
}

.whoarewe p strong {
	font-size: 2rem !important;
}

.additionalInfo ul {
	padding-left: 20px !important;
}

.contentManagement ul {
	padding-left: 20px !important;
}

.privacyPolicy ul {
	padding-left: 20px !important;
}